// 仪表盘
<template>
  <v-container
    id="dashboard"
    tag="section"
    fluid
  >
    <!-- <v-row class="ma-2">
      <v-col
        class="pa-0 pa-sm-4 pa-md-4 pa-lg-4 pa-xl-4"
      >-->
    <v-card
      :outlined="isXs ? true : false"
      :class="isXs ? 'pa-0 cardXs':''"
    >
      <v-row
        v-if="orgQX"
        class="pa-0 pa-sm-4 pa-md-4 pa-lg-4 pa-xl-4"
      >
        <v-col
          v-for="(form, i) in forms"
          v-show="orgForms.includes(form.id)"
          :key="i"
          class="py-0"
        >
          <card
            :form="form"
            :send-btn="
              form.id === wsFormId || form.id === wqFormId ? false : true
            "
            class="pa-0 pa-sm-4 pa-md-4 pa-lg-4 pa-xl-4"
            :title="form.name"
            :color="colors[i]"
            :icon="icons[i]"
            :form-id="form.id"
            :params="{ formId: form.id }"
          />
        </v-col>
      </v-row>
      <v-row
        v-else
        class="pa-0 pa-sm-4 pa-md-4 pa-lg-4 pa-xl-4"
      >
        <v-col
          v-for="(form, i) in forms"
          :key="i"
          class="py-0"
        >
          <card
            :form="form"
            :send-btn="form.id === tjFormId ? false : true"
            class="pa-0 pa-sm-4 pa-md-4 pa-lg-4 pa-xl-4"
            :title="form.name"
            :color="colors[i]"
            :icon="icons[i]"
            :form-id="form.id"
            :params="{ formId: form.id }"
          />
        </v-col>
      </v-row>
    </v-card>
    <!--  </v-col>
    </v-row> -->
    <!-- <v-row>
      <v-col
        cols="12"
      >
        <div
          class="font-weight-light mt-1"
          style="font-size: 25px"
        >
          项目标签
        </div>
      </v-col>
      <v-col
        cols="12"
      >
        <tags-nav />
      </v-col>
    </v-row> -->
    <v-row>
      <v-col
        v-if="isEdit"
        cols="12"
      >
        <v-card
          v-if="!isMarket"
          :class="isXs ? 'ma-0 cardTabs' :''"
        >
          <v-tabs
            v-model="carrierTab"
            fixed-tabs
            background-color="blue darken-3"
            dark
            icons-and-text
          >
            <v-tab
              v-for="(item, index) in titleNames1"
              :key="index"
              class="px-0"
            >
              <v-badge
                v-if="item.num"
                :color="item.color?item.color:'green'"
                :content="item.num"
              >
                <!-- <div>
                  <v-icon>fa fa-file-text</v-icon>
                </div> -->
                <div class="mt-2">
                  {{ item.title }}
                </div>
              </v-badge>
              <div
                v-else
                class="mt-2"
              >
                {{ item.title }}
              </div>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="carrierTab">
            <v-tab-item>
              <v-row>
                <new-audit
                  _from="audit"
                  a-status-list="0"
                  :form-id="wsFormId"
                  :form-name="wsName"
                  :title="titleNames1[carrierTab].title"
                />
                <new-audit
                  _from="audit"
                  a-status-list="0"
                  status-list="5,10,0"
                  :form-id="tjFormId"
                  :form-name="tjName"
                  :title="titleNames1[carrierTab].title"
                />
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <new-audit
                  _from="org"
                  status-list="-5"
                  :form-id="wsFormId"
                  :form-name="wsName"
                  :title="titleNames1[carrierTab].title"
                />
                <new-audit
                  _from="org"
                  status-list="-5"
                  :form-id="tjFormId"
                  :form-name="tjName"
                  :title="titleNames1[carrierTab].title"
                />
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <new-audit
                  _from="audit"
                  a-status-list="10"
                  :form-id="wsFormId"
                  :form-name="wsName"
                  :title="titleNames1[carrierTab].title"
                />
                <new-audit
                  _from="audit"
                  status-list="5,10,0"
                  a-status-list="10"
                  :form-id="tjFormId"
                  :form-name="tjName"
                  :title="titleNames1[carrierTab].title"
                />
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <new-audit
                  _from="org"
                  project-status2="VALID"
                  :form-id="wsFormId"
                  :form-name="wsName"
                  :title="titleNames1[carrierTab].title"
                />
                <new-audit
                  _from="audit"
                  status-list="5,10,0"
                  a-status-list="0,10"
                  project-status2="VALID"
                  :form-id="tjFormId"
                  :form-name="tjName"
                  :title="titleNames1[carrierTab].title"
                />
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
        <!-- 市级 -->
        <v-card
          v-if="isMarket"
          :class="isXs ? 'ma-0 cardTabs' :''"
        >
          <v-tabs
            v-model="carrierTab2"
            fixed-tabs
            background-color="blue darken-3"
            dark
            icons-and-text
          >
            <v-tab
              v-for="(item, index) in titleNames2"
              :key="index"
            >
              <v-badge
                v-if="item.num"
                :color="item.color?item.color:'green'"
                :content="item.num"
              >
                <!-- <div>
                  <v-icon>fa fa-file-text</v-icon>
                </div> -->
                <div class="mt-2">
                  {{ item.title }}
                </div>
              </v-badge>
              <div
                v-else
                class="mt-2"
              >
                {{ item.title }}
              </div>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="carrierTab2">
            <v-tab-item>
              <v-row>
                <new-audit
                  _from="audit"
                  a-status-list="0"
                  status-list="5,10,0"
                  :form-id="tjFormId"
                  :form-name="tjName"
                  :title="titleNames2[carrierTab2].title"
                />
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <new-audit
                  _from="org"
                  status-list="-5"
                  :form-id="tjFormId"
                  :form-name="tjName"
                  :title="titleNames2[carrierTab2].title"
                />
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <new-audit
                  _from="audit"
                  status-list="5,10,0"
                  a-status-list="10"
                  :form-id="tjFormId"
                  :form-name="tjName"
                  :title="titleNames2[carrierTab2].title"
                />
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <new-audit
                  _from="grant"
                  :form-id="tswsFormId"
                  :form-name="wsName"
                  :title="titleNames2[carrierTab2].title"
                  path="/city-list"
                  is-docking="是"
                />
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <new-audit
                  _from="audit"
                  status-list="5,10,0"
                  a-status-list="0,10"
                  project-status2="VALID"
                  :form-id="tjFormId"
                  :form-name="tjName"
                  :title="titleNames2[carrierTab2].title"
                />
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
      <!-- 代表处和区县 -->
      <v-col
        v-else-if="isDeputy || (orgQX && !isMarket)"
        cols="12"
      >
        <v-card
          :class="isXs ? 'ma-0 cardTabs' :''"
        >
          <v-tabs
            v-model="carrierTab3"
            fixed-tabs
            background-color="blue darken-3"
            dark
            icons-and-text
          >
            <v-tab
              v-for="(item, index) in titleNames3"
              :key="index"
            >
              <v-badge
                v-if="item.num"
                :color="item.color?item.color:'green'"
                :content="item.num"
              >
                <!-- <div>
                  <v-icon>fa fa-file-text</v-icon>
                </div> -->
                <div class="mt-2">
                  {{ item.title }}
                </div>
              </v-badge>
              <div
                v-else
                class="mt-2"
              >
                {{ item.title }}
              </div>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="carrierTab3">
            <v-tab-item>
              <v-row>
                <new-audit
                  v-if="isDeputy"
                  _from="org"
                  :form-id="wsFormId"
                  :form-name="wsName"
                  :title="titleNames3[carrierTab3].title"
                />
                <new-audit
                  v-if="orgQX && !isMarket"
                  _from="org"
                  :form-id="tjFormId"
                  :form-name="tjName"
                  :title="titleNames3[carrierTab3].title"
                />
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <new-audit
                  v-if="orgQX && !isMarket"
                  _from="grant"
                  :form-id="tswsFormId"
                  :form-name="wsName"
                  :title="titleNames3[carrierTab3].title"
                  path="/city-list"
                  is-docking="是"
                />
                <!-- 不传 is-docking 代表处默认接收-->
                <new-audit
                  v-if="isDeputy"
                  _from="grant"
                  :form-id="tstjFormId"
                  :form-name="tjName"
                  :title="titleNames3[carrierTab3].title"
                  path="/city-list2"
                />
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <new-audit
                  v-if="isDeputy"
                  _from="org"
                  status-list="-5"
                  :form-id="wsFormId"
                  :form-name="wsName"
                  :title="titleNames3[carrierTab3].title"
                />
                <new-audit
                  v-if="orgQX && !isMarket"
                  _from="org"
                  status-list="-5"
                  :form-id="tjFormId"
                  :form-name="tjName"
                  :title="titleNames3[carrierTab3].title"
                />
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <new-audit
                  v-if="isDeputy"
                  _from="org"
                  project-status2="VALID"
                  :form-id="wsFormId"
                  :form-name="wsName"
                  :title="titleNames3[carrierTab3].title"
                />
                <new-audit
                  v-if="orgQX && !isMarket"
                  _from="org"
                  project-status2="VALID"
                  :form-id="tjFormId"
                  :form-name="tjName"
                  :title="titleNames3[carrierTab3].title"
                />
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
      <!-- 没有角色 -->
      <v-col
        v-else
        cols="12"
      >
        <v-card
          :class="isXs ? 'ma-0 cardTabs' :''"
        >
          <v-tabs
            v-model="carrierTab4"
            fixed-tabs
            background-color="blue darken-3"
            dark
            icons-and-text
          >
            <v-tab
              v-for="(item, index) in titleNames4"
              :key="index"
            >
              <v-badge
                v-if="item.num"
                :color="item.color?item.color:'green'"
                :content="item.num"
              >
                <!-- <div>
                  <v-icon>fa fa-file-text</v-icon>
                </div> -->
                <div class="mt-2">
                  {{ item.title }}
                </div>
              </v-badge>
              <div
                v-else
                class="mt-2"
              >
                {{ item.title }}
              </div>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="carrierTab4">
            <v-tab-item>
              <v-row>
                <new-audit
                  _from="org"
                  status-list="-5"
                  :form-id="wsFormId"
                  :form-name="wsName"
                  :title="titleNames4[carrierTab4].title"
                />
                <new-audit
                  _from="org"
                  status-list="-5"
                  :form-id="tjFormId"
                  :form-name="tjName"
                  :title="titleNames4[carrierTab4].title"
                />
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <new-audit
                  _from="org"
                  project-status2="VALID"
                  :form-id="wsFormId"
                  :form-name="wsName"
                  :title="titleNames4[carrierTab4].title"
                />
                <new-audit
                  _from="org"
                  project-status2="VALID"
                  :form-id="tjFormId"
                  :form-name="tjName"
                  :title="titleNames4[carrierTab4].title"
                />
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
      <!-- 会商人员 -->
      <!-- <v-col
        v-if="isHshow"
        cols="12"
      >
        <v-card>
          <v-tabs
            v-model="carrierTab"
            fixed-tabs
            background-color="blue darken-3"
            dark
            icons-and-text
          >
            <v-tab
              v-for="(item, index) in titleNames1"
              :key="index"
            >
              {{ item }}
              <v-icon>fa fa-file-text</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="carrierTab">
            <v-tab-item>
              <v-row>
                <audit
                  :status="0"
                  type="report"
                  :is-title="false"
                />
                <audit
                  :status="-5"
                  type="report"
                  :is-title="false"
                />
                <audit
                  :status="10"
                  type="report"
                  :is-title="false"
                />
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <wait-project
                  type="report"
                  _from="org"
                  status="10"
                  :tzc-handle="tzcHandle"
                  :kfq-handle="kfqHandle"
                  :tzcfzr-handle="tzcfzrHandle"
                  :form-id="wstjFormId"
                  title="待办理项目"
                />
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <my-project
                  is-push="是"
                  type="report"
                  _from="org"
                  :form-id="wstjFormId"
                  title="已推送项目"
                />
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <my-project
                  is-push="NULL"
                  type="report"
                  _from="org"
                  :form-id="wstjFormId"
                  title="未推送项目"
                />
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
      <v-col
        v-else-if="isAdmin"
        cols="12"
      >
        <v-card>
          <v-tabs
            v-model="carrierTab2"
            fixed-tabs
            background-color="blue darken-3"
            dark
            icons-and-text
          >
            <v-tab
              v-for="(item, index) in titleNames2"
              :key="index"
            >
              {{ item }}
              <v-icon>fa fa-file-text</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="carrierTab2">
            <v-tab-item>
              <v-row>
                <audit
                  :status="0"
                  type="report"
                  :is-title="false"
                />
                <audit
                  :status="-5"
                  type="report"
                  :is-title="false"
                />
                <audit
                  :status="10"
                  type="report"
                  :is-title="false"
                />
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <my-project
                  is-push="是"
                  type="report"
                  _from="org"
                  :form-id="wstjFormId"
                  title="已推送项目"
                />
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <my-project
                  is-push="NULL"
                  type="report"
                  _from="org"
                  :form-id="wstjFormId"
                  title="未推送项目"
                />
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
      <v-col
        v-else-if="isDeputy"
        cols="12"
      >
        <v-card>
          <v-tabs
            v-model="carrierTab3"
            fixed-tabs
            background-color="blue darken-3"
            dark
            icons-and-text
          >
            <v-tab
              v-for="(item, index) in titleNames3"
              :key="index"
            >
              {{ item }}
              <v-icon>fa fa-file-text</v-icon>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="carrierTab3">
            <v-tab-item>
              <v-row>
                <my-project
                  is-push="是"
                  type="report"
                  _from="org"
                  :form-id="wsFormId"
                  title="已推送项目"
                />
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <my-project
                  is-push="NULL"
                  type="report"
                  _from="org"
                  :form-id="wsFormId"
                  title="未推送项目"
                />
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
      <v-col
        v-else-if="isMarket"
        cols="12"
      >
        <v-card>
          <v-tabs
            v-model="carrierTab4"
            fixed-tabs
            background-color="blue darken-3"
            dark
            icons-and-text
          >
            <v-tab
              v-for="(item, index) in titleNames4"
              :key="index"
            >
              {{ item }}
              <v-icon>fa fa-file-text</v-icon>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="carrierTab4">
            <v-tab-item>
              <v-row>
                <my-project
                  is-docking="NULL"
                  type="reportChild"
                  _from="grant"
                  :form-id="yqFormId"
                  title="待接收项目"
                />
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <my-project
                  is-docking="是"
                  type="reportChild"
                  _from="grant"
                  :form-id="yqFormId"
                  title="已接收项目"
                />
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <audit
                  :status="0"
                  type="report"
                />
                <audit
                  :status="-5"
                  type="report"
                />
                <audit
                  :status="10"
                  type="report"
                />
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <my-project
                  is-push="是"
                  type="report"
                  _from="org"
                  :form-id="tjFormId"
                  title="已推送项目"
                />
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <my-project
                  is-push="NULL"
                  type="report"
                  _from="org"
                  :form-id="tjFormId"
                  title="未推送项目"
                />
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
      <v-col
        v-else
        cols="12"
      >
        <v-card>
          <v-tabs
            v-model="carrierTab5"
            fixed-tabs
            background-color="blue darken-3"
            dark
            icons-and-text
          >
            <v-tab
              v-for="(item, index) in titleNames5"
              :key="index"
            >
              {{ item }}
              <v-icon>fa fa-file-text</v-icon>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="carrierTab5">
            <v-tab-item>
              <v-row>
                <my-project
                  is-push="是"
                  type="report"
                  _from="org"
                  :form-id="tjFormId"
                  title="已推送项目"
                />
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <my-project
                  is-push="NULL"
                  type="report"
                  _from="org"
                  :form-id="tjFormId"
                  title="未推送项目"
                />
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col> -->
    </v-row>
    <v-row>
      <v-col>
        <v-card :class="isXs ? 'py-4 cenbox' : 'pa-4 cenbox'">
          <v-btn
            x-large
            color="success"
            dark
            class="cenbtn"
          >
            平台数据汇总表
          </v-btn>
          <census />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { json } from 'body-parser';

  export default {
    name: 'DashboardDashboard',
    components: {
      card: () => import('./component/Card'),
      Census: () => import('./component/Census'),
      // tagsNav: () => import('./component/TagsNav'),
      Audit: () => import('./component/Audit'),
      NewAudit: () => import('./component/NewAudit'),
      myProject: () => import('./component/MyProject'),
      waitProject: () => import('./component/WaitProject.vue'),
    },
    data () {
      const wstjFormId =
        'bce0f632-42a8-4cf7-a30c-e5c6f00c6e62,defdcd74-fe79-4906-b3aa-dbdb0ce55afe';
      const wsFormId = 'bce0f632-42a8-4cf7-a30c-e5c6f00c6e62';
      const tswsFormId = '5afc5ec4-b2a0-49dc-b042-75d0079b396c';
      const tjFormId = 'defdcd74-fe79-4906-b3aa-dbdb0ce55afe';
      const tstjFormId = '76fa7297-55e4-4791-a239-897b24dad6a3';
      return {
        wstjFormId:
          'bce0f632-42a8-4cf7-a30c-e5c6f00c6e62,defdcd74-fe79-4906-b3aa-dbdb0ce55afe',
        // 外商
        wsFormId: 'bce0f632-42a8-4cf7-a30c-e5c6f00c6e62',
        // 外商 推送表单
        tswsFormId: '5afc5ec4-b2a0-49dc-b042-75d0079b396c',
        wsName: '外商投资合作项目库',
        // 园区
        yqFormId: '5afc5ec4-b2a0-49dc-b042-75d0079b396c',
        // 重点推介
        tjFormId: 'defdcd74-fe79-4906-b3aa-dbdb0ce55afe',
        // 重点推介 推送表单
        tstjFormId: '76fa7297-55e4-4791-a239-897b24dad6a3',
        tjName: '重点对外推介项目库',
        // 外商寻求
        wqFormId: 'eeadced3-7c53-4e4d-9f17-a334a28e10ad',
        tab: null,
        qxTab: null,
        carrierTab: 0,
        carrierTab2: 0,
        carrierTab3: 0,
        carrierTab4: 0,
        carrierTab5: 0,
        colors: ['#43A047', '#98D1DB', '#BB7889', '#82D6AC', '#97A5CC'],
        icons: [
          'iconfont icon-touziyixiangyuce',
          'iconfont icon-xiangmu',
          'iconfont icon-kehu',
          'iconfont icon-hezuo-tianchong',
          'iconfont icon-wodejianyijianyifankui',
        ],

        // 区县
        /* titleName3: ['推介项目'], */
        titleNames5: ['已推送项目', '未推送项目'],
        tips: [],
        showFillInProject: false,
        showMyProject: false,
        fillInProject: [],
        fillInProjectTotal: 0,
        projectId: '',
        isAdmin: false,
        // 市级
        isMarket: false,
        forms: null,
        // 区县园区显示的表单卡片
        orgForms: [
          'bce0f632-42a8-4cf7-a30c-e5c6f00c6e62',
          'eeadced3-7c53-4e4d-9f17-a334a28e10ad',
          'defdcd74-fe79-4906-b3aa-dbdb0ce55afe',
        ],
        reserve: 0,
        negotiation: 0,
        signing: 0,
        implementation: 0,
        tagsCount: [],
        king: false,
        municipalLevel: false,
        level: '',
        reportForms: [],
        carrierForms: [],
        orgQX: false,
        orgValue: '',
        isHshow: false,
        // 代表处
        isDeputy: false,
        tzcHandle: '',
        kfqHandle: '',
        tzcfzrHandle: '',
        isEdit: false,
        searchParame: {
          page: 0,
          size: 5,
        },
        titleNames1: [{ title: '待审核', num: 0, child: [{ _from: 'audit', aStatusList: '0', formId: wsFormId }, { _from: 'audit', aStatusList: '0', statusList: '5,10,0', formId: tjFormId }] },
                      { title: '待完善', color: 'red', num: 0, _from: 'org', statusList: '-5', formId: wstjFormId },
                      { title: '已通过', num: 0, child: [{ _from: 'audit', aStatusList: '10', formId: wsFormId }, { _from: 'audit', aStatusList: '10', statusList: '5,10,0', formId: tjFormId }] },
                      { title: '已推送', num: 0, child: [{ _from: 'org', formId: wsFormId, projectStatus2: 'VALID' }, { _from: 'audit', aStatusList: '0,10', statusList: '5,10,0', formId: tjFormId, projectStatus2: 'VALID' }] }],
        titleNames2: [{ title: '待审核', num: 0, _from: 'audit', aStatusList: '0', statusList: '5,10,0', formId: tjFormId },
                      { title: '待完善', color: 'red', num: 0, _from: 'org', statusList: '-5', formId: tjFormId },
                      { title: '已通过', num: 0, _from: 'audit', aStatusList: '10', statusList: '5,10,0', formId: tjFormId },
                      { title: '已接收', num: 0, _from: 'grant', formId: tswsFormId, isDocking: '是' },
                      { title: '已推送', num: 0, _from: 'audit', aStatusList: '0,10', statusList: '5,10,0', formId: tjFormId, projectStatus2: 'VALID' }],
        titleNames3: [
          { title: '已提交', num: 0, _from: 'org' },
          { title: '已接收', num: 0, _from: 'grant' },
          { title: '待完善', color: 'red', num: 0, _from: 'org' },
          { title: '已推送', num: 0, _from: 'org' },
        ],
        titleNames4: [
          {
            title: '待完善',
            color: 'red',
            num: 0,
            _from: 'org',
            formId: wstjFormId,
            statusList: '-5',
          },
          {
            title: '已推送',
            num: 0,
            _from: 'org',
            formId: wstjFormId,
            projectStatus2: 'VALID',
          },
        ],
        isXs: false,
      };
    },
    watch: {
      '$vuetify.breakpoint.name': {
        handler (name) {
          if (name === 'xs') {
            this.isXs = true
          } else if (name === 'sm') {
            this.isXs = false
          } else {
            this.isXs = false
          }
        },
        deep: true,
        // 代表在wacth里声明了这个方法之后立即先去执行handler方法
        immediate: true,
      },
    },
    async created () {
      this.isEdit =
        localStorage.isEdit === 'true' || localStorage.isEdit === true;
      this.orgQX = localStorage.orgQX === 'true' || localStorage.orgQX === true;
      this.isHshow =
        localStorage.isHshow === 'true' || localStorage.isHshow === true;
      this.isDeputy =
        localStorage.isDeputy === 'true' || localStorage.isDeputy === true;
      // isDeputy = this.isDeputy
      this.$nextTick(async () => {
        await this.getMeInfo();
      });
    },
    async mounted () {
      if (JSON.parse(localStorage.newForms).length) {
        this.forms = JSON.parse(localStorage.newForms)[0].children;
      }
      this.king = localStorage.level === 'province';
      // 是不是审核人员
      this.isAudit =
        localStorage.level === 'province' || localStorage.level === 'city';
      this.isAdmin = localStorage.isAdmin === 'true';
      this.level = localStorage.level;
      // 是不是市级审核
      this.isMarket = localStorage.isMarket === 'true';

      if (this.isEdit) {
        if (this.isMarket) {
          this.titleNames2.map((val, inx) => {
            const searchParame = JSON.parse(JSON.stringify(this.searchParame));
            val._from && (searchParame._from = val._from);
            val.formId && (searchParame.formId = val.formId);
            val.statusList && (searchParame.statusList = val.statusList);
            val.aStatusList && (searchParame.aStatusList = val.aStatusList);
            val.isDocking && (searchParame.isDocking = val.isDocking);
            val.projectStatus2 &&
              (searchParame.projectStatus2 = val.projectStatus2);
            this.getAuditProject2(searchParame, inx);
          });
        } else {
          this.titleNames1.map(async (val, inx) => {
            const searchParame = JSON.parse(JSON.stringify(this.searchParame));
            if (val.child) {
              let nums = 0
              for (let inm = 0; inm < val.child.length; inm++) {
                val.child[inm]._from && (searchParame._from = val.child[inm]._from);
                val.child[inm].formId && (searchParame.formId = val.child[inm].formId);
                val.child[inm].statusList && (searchParame.statusList = val.child[inm].statusList);
                val.child[inm].aStatusList && (searchParame.aStatusList = val.child[inm].aStatusList);
                val.child[inm].isDocking && (searchParame.isDocking = val.child[inm].isDocking);
                val.child[inm].projectStatus2 &&
                  (searchParame.projectStatus2 = val.child[inm].projectStatus2);

                nums += await this.getAuditProject1(searchParame);
              }
              this.$set(this.titleNames1[inx], 'num', nums);
            } else {
              val._from && (searchParame._from = val._from);
              val.formId && (searchParame.formId = val.formId);
              val.statusList && (searchParame.statusList = val.statusList);
              val.aStatusList && (searchParame.aStatusList = val.aStatusList);
              val.isDocking && (searchParame.isDocking = val.isDocking);
              val.projectStatus2 &&
                (searchParame.projectStatus2 = val.projectStatus2);
              this.getAuditProject(searchParame, inx);
            }
          });
        }
      } else if (this.isDeputy || (this.orgQX && !this.isMarket)) {
        if (this.isDeputy) {
          this.titleNames3 = [
            { title: '已提交', num: 0, _from: 'org', formId: this.wsFormId },
            { title: '已接收', num: 0, _from: 'grant', formId: this.tstjFormId },
            {
              title: '待完善',
              color: 'red',
              num: 0,
              _from: 'org',
              formId: this.wsFormId,
              statusList: '-5',
            },
            {
              title: '已推送',
              num: 0,
              _from: 'org',
              formId: this.wsFormId,
              projectStatus2: 'VALID',
            },
          ];
        } else {
          this.titleNames3 = [
            { title: '已提交', num: 0, _from: 'org', formId: this.tjFormId },
            {
              title: '已接收',
              num: 0,
              _from: 'grant',
              formId: this.tswsFormId,
              isDocking: '是',
            },
            {
              title: '待完善',
              color: 'red',
              num: 0,
              _from: 'org',
              formId: this.tjFormId,
              statusList: '-5',
            },
            {
              title: '已推送',
              num: 0,
              _from: 'org',
              formId: this.tjFormId,
              projectStatus2: 'VALID',
            },
          ];
        }
        this.titleNames3.map((val, inx) => {
          const searchParame = JSON.parse(JSON.stringify(this.searchParame));
          val._from && (searchParame._from = val._from);
          val.formId && (searchParame.formId = val.formId);
          val.statusList && (searchParame.statusList = val.statusList);
          val.isDocking && (searchParame.isDocking = val.isDocking);
          val.projectStatus2 &&
            (searchParame.projectStatus2 = val.projectStatus2);
          this.getAuditProject3(searchParame, inx);
        });
      } else {
        this.titleNames4.map((val, inx) => {
          const searchParame = JSON.parse(JSON.stringify(this.searchParame));
          val._from && (searchParame._from = val._from);
          val.formId && (searchParame.formId = val.formId);
          val.statusList && (searchParame.statusList = val.statusList);
          val.projectStatus2 &&
            (searchParame.projectStatus2 = val.projectStatus2);
          this.getAuditProject4(searchParame, inx);
        });
      }
    },
    methods: {
      async getAuditProject4 (searchParame, inx) {
        const resp = await this.$axios.get('/reports/query', {
          params: searchParame,
        });
        console.log('searchParame', searchParame);
        this.$set(this.titleNames4[inx], 'num', resp.data.data.totalElements);
      },
      async getAuditProject3 (searchParame, inx) {
        const resp = await this.$axios.get('/reports/query', {
          params: searchParame,
        });
        console.log('searchParame', searchParame);
        this.$set(this.titleNames3[inx], 'num', resp.data.data.totalElements);
      },
      async getAuditProject2 (searchParame, inx) {
        const resp = await this.$axios.get('/reports/query', {
          params: searchParame,
        });
        console.log('searchParame', searchParame);
        this.$set(this.titleNames2[inx], 'num', resp.data.data.totalElements);
      },
      async getAuditProject (searchParame, inx) {
        const resp = await this.$axios.get('/reports/query', {
          params: searchParame,
        });
        console.log('searchParame', searchParame);
        this.$set(this.titleNames1[inx], 'num', resp.data.data.totalElements);
      },
      async getAuditProject1 (searchParame, inx) {
        const resp = await this.$axios.get('/reports/query', {
          params: searchParame,
        });
        console.log('searchParame2', searchParame);
        return resp.data.data.totalElements
      },
      async getMeInfo () {
        const res = await this.$axios.get('/me');
        console.log('res', res);
        const roleName = res.data.data.roles;
        // 投资促进处负责人
        if (roleName.includes('ROLE_TZCJCFZRSHYJ')) {
          this.orgValue = 'ROLE_TZCJCFZRSHYJ';
          this.tzcfzrHandle = '待办理';
        // 开发区处
        } else if (roleName.includes('ROLE_KFQCNBYJ')) {
          this.orgValue = 'ROLE_KFQCNBYJ';
          this.kfqHandle = '待办理';
        // 投资促进处
        } else if (roleName.includes('ROLE_TZCJCNBYJ')) {
          this.orgValue = 'ROLE_TZCJCNBYJ';
          this.tzcHandle = 'NULL';
        } else {
          this.orgValue = '';
        }
      },
    },
  };
</script>
<style scoped lang="scss">
.cenbox {
  position: relative;
}

.cenbtn {
  position: absolute;
  left: 20px;
  top: -30px;
}
</style>
<style>
/* iconfont库 card中使用 */
@font-face {
  font-family: "iconfont";
  /* Project id 3366073 */
  src: url("//at.alicdn.com/t/font_3366073_1yfbyh5d1fij.woff2?t=1651135579048")
      format("woff2"),
    url("//at.alicdn.com/t/font_3366073_1yfbyh5d1fij.woff?t=1651135579048")
      format("woff"),
    url("//at.alicdn.com/t/font_3366073_1yfbyh5d1fij.ttf?t=1651135579048")
      format("truetype");
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-hezuo-tianchong:before {
  content: "\e65b";
}

.icon-kehu:before {
  content: "\e66f";
}

.icon-xiangmu:before {
  content: "\e60a";
}

.icon-touziyixiangyuce:before {
  content: "\e69b";
}

.icon-wodejianyijianyifankui:before {
  content: "\e67d";
}
.container .cardXs{
border:none
}
.cardTabs .v-tabs{
height:60px;
}
.cardTabs .v-tabs .v-slide-group__prev{
display:none !important
}
.cardTabs .v-tabs .v-badge{
margin-bottom:0 !important
}
.cardTabs .v-tabs .v-tabs-bar{
height:60px
}
.cardTabs .v-tabs .v-tab{
min-width:60px;
max-width:86px
}
</style>
